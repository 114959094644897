<template>
  <div>
    <div class="flex">
      <div class="flex flex-col flex-1">
        <div class="flex items-center mb-1">
          <b-icon :icon="icon" size="is-medium" type="is-primary" class="mr-2"></b-icon>
          <h5 class="font-bold mt-2">{{data.Code}}</h5>
        </div>
        <div>
          <small>{{warehouse}}</small>
          <span class="block text-gray-400 italic">{{data.TransactionType}}</span>
        </div>
      </div>
      <span>{{data.Date}}</span>
    </div>
    <div class="text-right" v-if="footer">
      <small class="text-right whitespace-pre">
        {{ footer }}
      </small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  computed: {
    icon () {
      return (this.data.TransactionType === 'Proxy' || this.data.TransactionType === 'Jastip') ? 'cart' : 'warehouse'
    },
    warehouse () {
      return this.data.WarehouseObj ? this.data.WarehouseObj.Name : this.data.Warehouse
    },
    footer () {
      let footer = ''
      if (this.data.QuantityWeight && this.data.QuantityVolume) footer += `${this.data.QuantityWeight}/kg / ${this.data.QuantityVolume}/kgs`

      if (this.data.Dimention) {
        if (footer) footer += '\r\n'
        footer += this.data.Dimention
      }
      return footer
    }
  }
}
</script>

<style>

</style>
